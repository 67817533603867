import React, { useEffect } from 'react'
import { Container, Text, Heading, Stack, Link } from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ReactRouterLink from '../components/link'

const IndexPage = () => {
  const stockistScript = () => {
    ;(function (s, t, o, c, k) {
      c = s.createElement(t)
      c.src = o
      c.async = 1
      k = s.getElementsByTagName(t)[0]
      k.parentNode.insertBefore(c, k)
    })(document, 'script', '//stockist.co/embed/v1/widget.min.js')
  }

  useEffect(() => {
    stockistScript()
  })

  return (
    <Layout>
      <Seo
        title="Where can you buy? | Mud Foods"
        description="Can't wait for them to be delivered? Check out one of our local stockists, where you can buy straight from your local shop."
      />
      <Container py={5}>
        <Stack spacing={4}>
          <Heading as="h1" size="lg">
            Find your nearest stockist
          </Heading>
          <Text>
            Can't wait for delivery? Enter your postcode to find your nearest
            stockist
          </Text>
          <div data-stockist-widget-tag="u4314">
            Loading store locator from Stockist store locator...
          </div>
          <Text>
            Would you like to sell Mud?{' '}
            <Link variant="inline" as={ReactRouterLink} to="/trade-wholesale">
              Fill out our form and get the pie rolling
            </Link>
          </Text>
        </Stack>
      </Container>
    </Layout>
  )
}

export default IndexPage
